import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';

// Registra los elementos necesarios de Chart.js
ChartJS.register(ArcElement, Tooltip);

const SimpleChart = () => {
  // Datos estáticos para el gráfico
  const data = {
    datasets: [
      {
        data: [30, 70], // Valores del gráfico
        backgroundColor: ['#ef4444', '#e5e7eb'], // Colores del gráfico
        borderWidth: 0, // Sin bordes
      },
    ],
  };

  // Opciones básicas del gráfico
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: false, // Desactivar tooltips
      },
      legend: {
        display: false, // Ocultar leyenda
      },
    },
  };

  return (
    <div style={{ width: '320px', height: '160px' }}>
      <Doughnut data={data} options={options} />
    </div>
  );
};

export default SimpleChart;