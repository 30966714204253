
import React from 'react';
import {calculateLectureAverages,calculateDaysInfo,calculateInorm} from '../matematic/habitolector.js'
import HabitContainer from '../progreso/HabitContainer.jsx';


// Mapeo de posibles colores para cada métrica:
const colorMapping = {
    red: { from: 'from-red-400', to: 'to-red-400' },
    orange: { from: 'from-orange-400', to: 'to-orange-400' },
    yellow: { from: 'from-yellow-400', to: 'to-yellow-400' },
    blue: { from: 'from-blue-400', to: 'to-blue-400' },
    green: { from: 'from-green-400', to: 'to-green-400' },
  };

  const getTailwindColorKey = (value) => {
    if (value === 0) return 'gray';
    if (value < 0.45) return 'red';
    if (value < 0.65) return 'orange';
    if (value < 0.8) return 'yellow';
    if (value < 0.9) return 'blue';
    return 'green';
  };
  
 
// Función para obtener el tag de desempeño según el valor (entre 0 y 1)
const getPerformanceTag = (value) => {
    if (value === 0) return 'Sin Lecturas';
    if (value < 0.45) return 'Pre-inicio';
    if (value < 0.65) return 'Inicio';
    if (value < 0.8) return 'En Proceso';
    if (value < 0.9) return 'Logrado';
    return 'Satisfactorio';
};

const HeaderResults = ({ myReadingData,data }) => {

  let comprensionlectoramonthly;
  let lecturesmonthly;
  var lecturas = calculateLectureAverages(data)
  comprensionlectoramonthly = lecturas.comprensionlectora
  lecturesmonthly = lecturas.lecturasdistintas

  var dayslecturemonthly = calculateDaysInfo(data)
  

  var habitolectormonthly = calculateInorm(lecturesmonthly,  dayslecturemonthly.daysWithLectures, dayslecturemonthly.daysElapsed, 1.5, dayslecturemonthly.daysElapsed*12)

  
  const habitColorKey = getTailwindColorKey(habitolectormonthly);
  const compColorKey = getTailwindColorKey(comprensionlectoramonthly);
  const habitClasses = colorMapping[habitColorKey] || { from: 'from-gray-400', to: 'to-gray-400' };
  const compClasses = colorMapping[compColorKey] || { from: 'from-gray-400', to: 'to-gray-400' };
  const habitTag = getPerformanceTag(habitolectormonthly);
  const compTag = getPerformanceTag(comprensionlectoramonthly);
  return (
    <>
        <HabitContainer myReadingData={myReadingData} habitolectormonthly={habitolectormonthly} comprensionlectoramonthly={comprensionlectoramonthly}></HabitContainer>
        <div className={`sticky top-0 z-1  bg-gradient-to-r  ${compClasses.from} ${habitClasses.to} text-white shadow-lg rounded-lg p-2 flex  flex-row justify-between items-center`}>
            {/* Sección Izquierda: Comprensión Lectora */}
            <div className="flex-1 flex flex-col items-start space-y-1 md:space-y-2 ">
                {/* 1. Tag de resultado */}
                <h3 className="text-xl md:text-2xl font-bold">{compTag}</h3>
                {/* 2. Detalle: porcentaje */}
                <p className="text-sm opacity-90">{Math.round(comprensionlectoramonthly * 100)}%</p>
                <p className="text-sm opacity-90">aciertos</p>
                {/* 3. Etiqueta descriptiva con icono */}

                <div className="flex items-center space-x-2">
                <svg className="w-4 h-4 text-white opacity-80" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"></path>
                </svg>
                <span className="italic text-sm opacity-90">Comprensión Lectora</span>
                </div>
            </div>
        
            {/* Sección Derecha: Hábito Lector */}
            <div className="flex-1 flex flex-col items-end space-y-1 md:space-y-2">
                {/* 1. Tag de resultado */}
                <h3 className="text-xl md:text-2xl font-bold">{habitTag}</h3>
                {/* 2. Detalle: lecturas y días */}
                <p className="text-sm text-right opacity-90">
                {lecturesmonthly} lecturas<br />
                he leído {dayslecturemonthly.daysWithLectures} de {dayslecturemonthly.daysElapsed} días
                </p>
                {/* 3. Etiqueta descriptiva con icono */}
                <div className="flex items-center space-x-2">
                <span className="italic text-sm opacity-90">Hábito Lector</span>
                <svg className="w-4 h-4 text-white opacity-80" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"></path>
                </svg>
                </div>
            </div>
        </div>

        
    </>
    )
}

export default HeaderResults;