import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';

// Registra los elementos necesarios de Chart.js
ChartJS.register(ArcElement, Tooltip);

const SpeedometerChart = ({ value }) => {
  // Validar que el valor esté entre 0 y 1
  const clampedValue = Math.min(Math.max(value, 0), 1);

  // Determinar el color y el estado según el valor
  const getStatusAndColor = (value) => {
    if (value < 0.45) return { status: 'Pre-inicio', color: '#ef4444' };
    if (value < 0.65) return { status: 'Inicio', color: '#f97316' };
    if (value < 0.8) return { status: 'En Proceso', color: '#eab308' };
    if (value < 0.9) return { status: 'Logrado', color: '#3b82f6' };
    return { status: 'Satisfactorio', color: '#22c55e' };
  };

  const { status, color } = getStatusAndColor(clampedValue);

  // Configuración de los datos del gráfico
  const data = {
    datasets: [
      {
        data: [clampedValue, 1 - clampedValue], // Valor actual y el resto
        backgroundColor: [color, '#e5e7eb'], // Color del gauge y fondo
        borderWidth: 0, // Sin bordes
      },
    ],
  };

  // Opciones del gráfico
  const options = {
    rotation: -Math.PI, // Iniciar desde la izquierda
    circumference: Math.PI, // Crear un semicírculo
    cutout: '70%', // Grosor del gauge
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: false, // Desactivar tooltips
      },
      legend: {
        display: false, // Ocultar leyenda
      },
    },
  };

  return (
    <div style={{ width: '320px', height: '160px', position: 'relative' }}>
      <Doughnut data={data} options={options} />
      {/* Texto superpuesto */}
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
        }}
      >
        <div style={{ fontSize: '24px', fontWeight: 'bold', color }}>
          {status}
        </div>
        <div style={{ fontSize: '18px', color: '#6b7280' }}>
          {Math.round(clampedValue * 100)}%
        </div>
      </div>
    </div>
  );
};

export default SpeedometerChart;