// CalendarView.jsx
import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

/**
 * Parsea una fecha en formato "YYYY-MM-DD" a un objeto Date en horario local.
 */
function parseLocalDate(dateString) {
  const [year, month, day] = dateString.split('-').map(Number);
  return new Date(year, month - 1, day);
}

const colorMapping = {
  red: {
    100: 'bg-red-100',
    300: 'bg-red-300',
    500: 'bg-red-500',
    700: 'bg-red-700',
    900: 'bg-red-900',
  },
  orange: {
    100: 'bg-orange-100',
    300: 'bg-orange-300',
    500: 'bg-orange-500',
    700: 'bg-orange-700',
    900: 'bg-orange-900',
  },
  yellow: {
    100: 'bg-yellow-100',
    300: 'bg-yellow-300',
    500: 'bg-yellow-500',
    700: 'bg-yellow-700',
    900: 'bg-yellow-900',
  },
  blue: {
    100: 'bg-blue-100',
    300: 'bg-blue-300',
    500: 'bg-blue-500',
    700: 'bg-blue-700',
    900: 'bg-blue-900',
  },
  green: {
    100: 'bg-green-100',
    300: 'bg-green-300',
    500: 'bg-green-500',
    700: 'bg-green-700',
    900: 'bg-green-900',
  },
  purple: {
    100: 'bg-purple-100',
    300: 'bg-purple-300',
    500: 'bg-purple-500',
    700: 'bg-purple-700',
    900: 'bg-purple-900',
  },
  gray: {
    300: 'bg-gray-300',
  },
};

/**
 * Retorna una clase de color según el total de lecturas y el puntaje de comprensión.
 */
function getColorClass(totalLectures, avgDailyScore) {
  // Si no hay lecturas, devolvemos un gris muy suave
  if (totalLectures === 0) return 'bg-gray-300';

  // Primero, definimos el color base según el promedio diario
  let baseColor = '';
  if (avgDailyScore <= 0.45) {
    baseColor = 'red';
  } else if (avgDailyScore <= 0.65) {
    baseColor = 'orange';
  } else if (avgDailyScore <= 0.80) {
    baseColor = 'yellow';
  } else if (avgDailyScore <= 0.90) {
    baseColor = 'blue';
  } else if (avgDailyScore <= 1) {
    baseColor = 'green';
  } else {
    baseColor = 'purple';
  }

  // Usamos varios umbrales para elegir la intensidad del color
  if (totalLectures < 10) return colorMapping[baseColor][100];
  else if (totalLectures < 30) return colorMapping[baseColor][300];
  else if (totalLectures < 50) return colorMapping[baseColor][500];
  else if (totalLectures < 70) return colorMapping[baseColor][700];
  else return colorMapping[baseColor][900];
};

/**
 * Componente que muestra cada bolita (círculo) con su tooltip.
 */
const TooltipCircle = ({ lecturas, index, colorClass }) => {
  const [hover, setHover] = useState(false);
  const [tooltipStyle, setTooltipStyle] = useState({});
  const containerRef = useRef(null);
  const tooltipRef = useRef(null);

  /**
   * Formatea la fecha a un formato como "Vie, 18 Feb".
   */
  function formatDate(dateString) {
    const date = parseLocalDate(dateString);
    const options = { weekday: 'short', day: '2-digit', month: 'short' };
    let formatted = date.toLocaleDateString('es-ES', options);
    formatted = formatted.replace(/\./g, '');
    const parts = formatted.split(',');
    if (parts.length === 2) {
      const weekday = parts[0].trim();
      const rest = parts[1].trim();
      const capitalizedWeekday = weekday.charAt(0).toUpperCase() + weekday.slice(1);
      const restParts = rest.split(' ');
      if (restParts.length === 2) {
        const day = restParts[0];
        const month = restParts[1].charAt(0).toUpperCase() + restParts[1].slice(1);
        return `${capitalizedWeekday}, ${day} ${month}`;
      }
      return `${capitalizedWeekday}, ${rest}`;
    }
    return formatted;
  }
  const formattedDate = formatDate(lecturas.date);

  // Calcula la posición del tooltip para que no se salga del viewport.
  useEffect(() => {
    if (hover && containerRef.current && tooltipRef.current) {
      const circleRect = containerRef.current.getBoundingClientRect();
      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      const padding = 10; // espacio mínimo respecto a los bordes
      let left = circleRect.left + circleRect.width / 2 - tooltipRect.width / 2;
      if (left < padding) left = padding;
      if (left + tooltipRect.width > window.innerWidth - padding) {
        left = window.innerWidth - padding - tooltipRect.width;
      }
      let top = circleRect.top - tooltipRect.height - 5;
      if (top < padding) top = circleRect.bottom + 5;
      setTooltipStyle({ left, top, position: 'fixed' });
    }
  }, [hover]);

  return (
    <div
      ref={containerRef}
      className="relative inline-block"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <motion.div
        className={`w-8 h-8 rounded-full ${colorClass}`}
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ delay: index * 0.1, type: 'spring', stiffness: 100 }}
      />
      <AnimatePresence>
        {hover && (
          <motion.div
            ref={tooltipRef}
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
            className="px-2 py-1 bg-white text-gray-800 text-xs font-semibold rounded-lg border-2 border-blue-400 shadow-lg z-10 text-center"
            style={{ maxWidth: 'calc(100vw - 20px)', ...tooltipStyle }}
          >
            <div className="flex flex-col gap-1">
              <span><strong>Día:</strong> {formattedDate}</span>
              <span>
                <strong>Lecturas:</strong> {lecturas.total_lectures} <br />
                <strong>Comprensión:</strong> {lecturas.avg_daily_score.toFixed(2)}
              </span>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

/**
 * Componente que organiza los datos en formato calendario (grid de 7 columnas, de sábado a viernes).
 * Se insertan celdas vacías para alinear el primer día y completar la última fila.
 * Además, se marca la bolita del día actual con borde verde y se pinta en gris las fechas futuras.
 */
function CalendarView({ readingData = [] }) {
  if (!readingData || readingData.length === 0) return null;

  // Se asume que readingData está ordenado por fecha ascendente.
  const firstDate = parseLocalDate(readingData[0].date);
  // Calcula el offset para que el primer día del mes se alinee según la semana que inicia en sábado.
  const offset = (firstDate.getDay() + 1) % 7;

  // Crea un array de celdas: primero celdas vacías y luego los datos.
  const cells = new Array(offset).fill(null).concat(readingData);
  const remainder = cells.length % 7;
  if (remainder > 0) {
    const fillCount = 7 - remainder;
    for (let i = 0; i < fillCount; i++) {
      cells.push(null);
    }
  }

  // Divide en filas de 7 celdas.
  const rows = [];
  for (let i = 0; i < cells.length; i += 7) {
    rows.push(cells.slice(i, i + 7));
  }

  // Etiquetas de los días (semana iniciando en sábado).
  const dayLabels = ['Sáb', 'Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie'];

  return (
    <div className="w-full p-2">
      <div className="max-w-md mx-auto">
        {/* Encabezado con los días de la semana */}
        <div className="grid grid-cols-7 gap-1 text-center font-semibold text-sm mb-1">
          {dayLabels.map((day, index) => (
            <div key={index}>{day}</div>
          ))}
        </div>
        {/* Grid del calendario */}
        <div className="grid grid-cols-7 gap-1">
          {rows.map((row, rowIndex) =>
            row.map((cell, colIndex) => {
              if (cell) {
                // Compara la fecha de la celda con el día actual.
                const cellDate = parseLocalDate(cell.date);
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                cellDate.setHours(0, 0, 0, 0);
                const isToday = cellDate.getTime() === today.getTime();
                const isFuture = cellDate.getTime() > today.getTime();
                // Obtiene la clase base según los datos.
                let bubbleColorClass = getColorClass(cell.total_lectures, cell.avg_daily_score);
                // Si es el día actual, se le agrega un borde verde.
                if (isToday) {
                  bubbleColorClass += " border-2 border-green-500";
                }
                // Si es una fecha futura, se pinta en un gris más suave.
                if (isFuture) {
                  bubbleColorClass = "bg-gray-100";
                }
                return (
                  <TooltipCircle
                    key={rowIndex * 7 + colIndex}
                    lecturas={cell}
                    index={rowIndex * 7 + colIndex}
                    colorClass={bubbleColorClass}
                  />
                );
              } else {
                // Celda vacía para completar la grilla.
                return <div key={rowIndex * 7 + colIndex} className="w-8 h-8" />;
              }
            })
          )}
        </div>
      </div>
    </div>
  );
}

export default CalendarView;
