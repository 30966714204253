import { useParams } from "react-router-dom";
import Fetchdatos from "../components/Fetchdatos";
import uuid from 'react-uuid'
import { useNavigate  } from 'react-router-dom';
import { useState,useEffect } from "react";
import ReactMarkdown from 'react-markdown';
import { Markdown } from "spectacle";

import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css'; // Importación del CSS de KaTeX
import Loader from "../components/LoaderSumaq";



var respuestas = {};
var calificacion = {};
var lectureid = 'id-lectura-generico'
var total_preguntas_del_examen = 0
var userid = 'usuario-generico'
var lectureresultid = 'id-resultado-generico' 

function showCalificacion(history){
    var nota = 0
    var total = 0
    for (var key in calificacion){
        nota = nota + calificacion[key]
        total = total + 1
    }

    var datacalificada = {
        'respuestas':respuestas,
        'calificacion': calificacion,
        'cantidad_acertadas': nota,
        'total_preguntas':total
    }
    Postlectura(datacalificada)
    total_preguntas_del_examen = 0;
    respuestas = {};
    calificacion = {};

    
    history(`/lecturas/resultado/${lectureresultid}/${nota}`);

}

function getLecturaDetail(id){
    const url = "https://2dmtiaujne.execute-api.us-east-2.amazonaws.com/demo/dynamodbmanager/getlecturedetails/getlecturebyid?id="+id

    const data =  Fetchdatos(url,{},'get')
    
    if (data != null){
        return JSON.parse(data[0].body).Item
    }
    
    return null
}



const Alternativa = ({pregunta, texto, framework, cambioRadioFramework}) => {
    return (
        <div className="form-check">
            <input className="form-check-input" type="radio" name={pregunta} id={pregunta+texto} value={texto} checked={framework==texto?true:false} onChange={cambioRadioFramework}></input>
            <label className="form-check-label" for={pregunta+texto}>
              <ReactMarkdown remarkPlugins={[remarkMath]} rehypePlugins={[rehypeKatex]}
              components={{
                p: ({ node, ...props }) => <span {...props} />,
              }}
              >
                {texto}
              </ReactMarkdown>
            </label>
        </div>
    )
}

const Pregunta = ({pregunta, alternativas}) => {
    const  [framework, setFramework] = useState('nothing')
    

    const cambioRadioFramework=e=>{ 
        setFramework(e.target.value)
        respuestas[pregunta] = e.target.value;
        alternativas.map((alternativa)=>{
            if(alternativa.texto == respuestas[pregunta])
                if(alternativa.es_correcta == true)
                    calificacion[pregunta] = 1
                else
                    calificacion[pregunta] = 0
        })

    } 
    return (
      <>
        <b><ReactMarkdown remarkPlugins={[remarkMath]} rehypePlugins={[rehypeKatex]}>{pregunta}</ReactMarkdown></b>
        {alternativas.map(
            (alternativa) => 
            (
                <Alternativa pregunta={pregunta} texto={alternativa.texto} framework={framework} cambioRadioFramework={cambioRadioFramework}></Alternativa>
            )
            )
        }
                    
      </>
    )
}

function Postlectura(data_result) {
    
        async function fetchData() {
          try {
            lectureresultid = uuid()

            const currentDate = new Date();
            const datetime_exam = currentDate;
            // Obtener año y mes en formato YYYY_MM
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript van de 0 a 11, por eso se suma 1
            const MES_CARGA = `${year}_${month}`;



            const response = await fetch(
              "https://2dmtiaujne.execute-api.us-east-2.amazonaws.com/demo/dynamodbmanager/postlectureresult",
  
              {
                method:'post',
                body: JSON.stringify(
                  {
                      operation: "create", 
                      payload: {"Item": {
                        "lr_id": userid+'+'+lectureid +'+'+lectureresultid, 
                        "cantidad_acertadas": data_result.cantidad_acertadas,
                        "total_preguntas": total_preguntas_del_examen,
                        "total_respondidas":data_result.total_preguntas,
                        "calificacion":data_result.calificacion,
                        "respuestas":data_result.respuestas,
                        "lecture_id":lectureid,
                        "usuario_id":userid,
                        "datetime_exam":datetime_exam,
                        "mes_carga":MES_CARGA
                        }
                    }
                  }
                )
              }
            );
            if (response.ok) {
              const lista = await response.json();
  
            } else {
                console.log("Hubo un error al obtener la lista de lecturas.");
            }
          } catch (error1) {
            console.log("No pudimos hacer la solicitud para obtener las lecturas");
          }
        }
        fetchData();
      
  }

const _CardNuevo = ({ children }) => (
  <div style={_styles.card}>
    {children}
  </div>
);

const _QuoteBlockNuevo = ({ children }) => (
  <blockquote style={_styles.blockquote}>
    {children}
  </blockquote>
);

const _styles = {
    card: {
      maxWidth: '800px',
      margin: '20px auto',
      padding: '20px',
      border: '1px solid #ccc',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      background: 'lightgreen'
    },
    blockquote: {
      fontStyle: 'italic',
      borderLeft: '4px solid #007bff',
      paddingLeft: '16px',
      color: '#555',
    },
  };


  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1)); // Selecciona un índice aleatorio
      [array[i], array[j]] = [array[j], array[i]]; // Intercambia los elementos
    }
    return array;
  }
  

export default function TestLectura({user}) {
    
    const history = useNavigate ();

    const params = useParams();
    const funcionprueba = showCalificacion;
    lectureid = params.lecturaid;
    userid = user.username

    var lectura = getLecturaDetail(params.lecturaid) 
    if(lectura == null){
        return <><Loader></Loader></>
    }else{
        console.log(lectura)
        return (
          lectura.active == false?
          <div className='lectura-container container d-flex justify-content-center align-items-center h-100'>
            <div className='row'>
              <_CardNuevo><_QuoteBlockNuevo>
                Gracias por tu espiritu literario, Tu creación literaria está siendo revisada por un equipo de docentes de la UGEL y Sumaqtai.
              </_QuoteBlockNuevo></_CardNuevo>
              <a  href="/"  className='btn btn-outline-primary rounded-5'>
                        Ir a mis lecturas
              </a>
            </div>
          </div>
            :
            <div className='lectura-container container d-flex justify-content-center align-items-center h-100'>
                <div className='row'>
                    <h1> {JSON.parse(lectura.test).titulo}</h1>
                    <h3> <i>#Lectura Rápida#</i> </h3>
                    <h3> <i>{lectura.tipo_lectura}</i> </h3>

                    <h5> #{JSON.parse(lectura.test).courses_hashtag}</h5>
                    { <h5> Nivel: {JSON.parse(lectura.test).level_of_school}</h5>
                    /*<h5> Grado: {JSON.parse(lectura.test).level_of_grade}</h5>
                    <h5> Dificultad: {JSON.parse(lectura.test).difficulty}</h5> */}
                    <br/>
                    <br/>
                    <div> {
                    
                   /*  JSON.parse(lectura.test).lectura.split("\\n").map((parrafo)=>(<> <ReactMarkdown>{parrafo}</ReactMarkdown>  </>)) */
                   <ReactMarkdown remarkPlugins={[remarkMath]} rehypePlugins={[rehypeKatex]}>{JSON.parse(lectura.test).lectura }</ReactMarkdown>
                    }</div>
                            {lectura.texto==null?'':
                            <_CardNuevo><_QuoteBlockNuevo>{lectura.texto==null?'':lectura.texto.split("\\n").map((parrafo)=>(<> <ReactMarkdown remarkPlugins={[remarkMath]} rehypePlugins={[rehypeKatex]}>{parrafo}</ReactMarkdown>  </>)) }</_QuoteBlockNuevo></_CardNuevo>
                            }
                            
                    <div>
                        {
                        JSON.parse(lectura.test).titulo !='Loading'? 
                        shuffleArray(JSON.parse(lectura.test).preguntas).map(
                            pregunta => {
                                var _alternativasaleatorias = shuffleArray(pregunta.alternativas)
                                total_preguntas_del_examen+=1
                                return (<>
                                    <Pregunta pregunta={pregunta.pregunta} alternativas={_alternativasaleatorias} ></Pregunta>
                                    <br></br>
                                </>)
                                
                        })                
                        :''
                        }
                    </div>
                    <a onClick={() => funcionprueba(history)} className='btn btn-outline-primary rounded-5'>
                        Evaluar mis respuestas
                    </a>
                </div>
            </div>
            )
    }


    
}