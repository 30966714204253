import React from "react";

const ButtonTemperatureLecture = ({
  children,
  onClick,
  className = "",
  color = "white",
  size = "medium",
}) => {
  const colorClasses = {
    blue: "bg-blue-600 hover:bg-blue-700",
    red: "bg-red-600 hover:bg-red-700",
    green: "bg-green-600 hover:bg-green-700",
  };

  const sizeClasses = {
    small: "py-1 px-1 text-sm text-center",
    medium: "py-1 px-2 text-base text-center",
    large: "py-1 px-2 text-lg text-center",
  };

  return (
    <div
      className={`${colorClasses[color]} ${sizeClasses[size]} text-white rounded-lg transition-colors duration-1000 cursor-pointer ${className}`}
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          onClick();
        }
      }}
    >
      {children}
    </div>
  );
};

export default ButtonTemperatureLecture;