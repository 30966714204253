// HabitContainer.jsx
import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import MonthlyReadingHabit from './MonthlyReadingHabit';
import CalendarView from './CalendarView';
import Speedometer from '../matematic/graph/Speedometer';
import ButtonTemperatureLecture from '../elements/ButtonTemperatureLecture';
/**
 * Parsea una fecha en formato "YYYY-MM-DD" a un objeto Date en horario local.
 */
function parseLocalDate(dateString) {
  const [year, month, day] = dateString.split('-').map(Number);
  return new Date(year, month - 1, day);
}

/**
 * Obtiene el mes y año en formato "Mes AAAA" a partir de myReadingData.
 * Se utiliza el primer elemento del array para determinar el mes.
 */
function getMonthYear(readingData) {
  if (!readingData || readingData.length === 0) return "";
  const firstDate = parseLocalDate(readingData[0].date);
  // Obtiene el nombre completo del mes en español (ej.: "febrero")
  const month = firstDate.toLocaleString('es-ES', { month: 'long' });
  const year = firstDate.getFullYear();
  // Capitaliza la primera letra del mes
  const monthCapitalized = month.charAt(0).toUpperCase() + month.slice(1);
  return `${monthCapitalized} ${year}`;
}

const HabitContainer = ({ myReadingData, habitolectormonthly, comprensionlectoramonthly }) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const headerText = getMonthYear(myReadingData);

  return (
    <div className="w-full flex bg-white p-1 rounded shadow-lg relative">
  {/* Contenedor de las dos filas */}
  <div className=" grid-rows-2 gap-1 w-full">
    {/* Primera fila */}
    <div className=" w-full bg-white p-2 rounded-lg shadow-lg relative">
      {/* Componente que muestra las bolitas de lectura */}
      <MonthlyReadingHabit readingData={myReadingData} />
    </div>

    {/* Segunda fila */}
    <div
  className="w-full bg-gradient-to-r from-blue-500 to-red-500 rounded-lg overflow-hidden hover:from-blue-600 hover:to-red-600 transition-all duration-300"
>
  

      {/* Botón personalizado */}
      <ButtonTemperatureLecture
        className="w-full" // Asegura que el botón ocupe todo el ancho
        onClick={() => setShowCalendar(true)}
      >
        mi Termómetro Lector 
      </ButtonTemperatureLecture>
    </div>

  </div>
      

      





      {/* Modal con el CalendarView */}
      <AnimatePresence>
        {showCalendar && (
          <motion.div
            className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              className="bg-white p-4 rounded shadow-lg max-w-lg w-full relative mx-4"
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.8 }}
            >
              
              {/* Encabezado del modal: mes y año dinámicos y subtítulo centrado */}
              <div className="text-center mb-2">
                <h2 className="text-lg font-bold mb-0">{headerText}</h2>
              </div>

              {/* Botón para cerrar el modal */}
            <div
                className=" calendarclosebutton top-2 right-2 text-gray-600 hover:text-gray-900 text-2xl focus:outline-none"
                onClick={() => setShowCalendar(false)}
                title="Cerrar calendario"
              >
                &times;
              </div>


              <CalendarView readingData={myReadingData} />
                <p className="text-center text-sm italic">Lectura diaria</p>

              <Speedometer value={habitolectormonthly} />
                <p className="text-center text-sm italic">Hábito Lector</p>
              <Speedometer value={comprensionlectoramonthly} />
                <p className="text-center text-sm italic">Comprensión Lectora</p>
            </motion.div>

            

            
          </motion.div>
        )}
      </AnimatePresence>

      
    </div>
  );
};

export default HabitContainer;
