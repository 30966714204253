/**
 * Calcula el índice normalizado I_norm.
 *
 * @param {number} Lm    - Número total de lecturas (distintas) en el mes.
 * @param {number} Dr    - Número de días en los que se leyó.
 * @param {number} Dm    - Total de días del mes (por ejemplo, 30).
 * @param {number} alpha - Parámetro de ponderación para la regularidad.
 * @param {number} [Lref=360] - Meta de lecturas en el mes (por defecto 360, es decir, 12 x 30).
 * @returns {number} Índice normalizado (valor entre 0 y 1).
 */
export function calculateInorm(Lm, Dr, Dm, alpha, Lref = 360) {
    // Calcula el factor de regularidad: (Dr/Dm)^alpha
    const regularityFactor = Math.pow(Dr / Dm, alpha);
  
    // Calcula el valor "bruto": Lm * (Dr/Dm)^alpha
    const bruto = Lm * regularityFactor;
  
    // Calcula el numerador: ln(1 + bruto)
    const numerator = Math.log(1 + bruto);
  
    // Calcula el denominador: ln(1 + Lref)
    const denominator = Math.log(1 + Lref);
  
    // Retorna el índice normalizado
    const inorm = numerator / denominator;
    return parseFloat(inorm.toFixed(2))
  }



/**
 * Calcula el promedio (para cada lecture_id) de cantidad_acertadas/total_preguntas.
 *
 * @param {Array<Object>} data - Array de objetos con las propiedades:
 *    cantidad_acertadas, total_preguntas, lecture_id, etc.
 * @returns {Object} Un objeto donde cada propiedad es un lecture_id y su valor es el promedio calculado.
 */
export function calculateLectureAverages(data) {
  // Usamos un objeto para agrupar por lecture_id
  const groups = {};

  data.forEach(item => {
    // Evitamos división por cero
    const ratio = item.total_preguntas ? (item.cantidad_acertadas / item.total_preguntas) : 0;

    if (!groups[item.lecture_id]) {
      groups[item.lecture_id] = { sum: 0, count: 0 };
    }
    groups[item.lecture_id].sum += ratio;
    groups[item.lecture_id].count += 1;
  });

  // Calculamos el promedio para cada lecture_id
  const averages = {};
  for (const lectureId in groups) {
    averages[lectureId] = groups[lectureId].sum / groups[lectureId].count;
  }


  const keys = Object.keys(averages); // Array con los lecture_id
  const sumOfAverages = keys.reduce((acc, key) => acc + averages[key], 0);
  const overallAverage = sumOfAverages / keys.length;
  return ({'comprensionlectora':parseFloat(overallAverage.toFixed(2)),'lecturasdistintas': keys.length});
}

/**
 * Calcula los días transcurridos (desde la fecha más temprana en datetime_exam hasta hoy)
 * y la cantidad de días en que se registró al menos una lectura (lectura distinta).
 *
 * @param {Array<Object>} data - Array de objetos que deben incluir la propiedad datetime_exam (formato ISO).
 * @returns {Object} Un objeto con:
 *   - daysElapsed: número de días transcurridos desde el primer registro hasta hoy.
 *   - daysWithLectures: cantidad de días únicos en que hay registro (lectura).
 */
export function calculateDaysInfo(data) {
  // Usamos un Set para almacenar las fechas (en formato YYYY-MM-DD) y determinar días únicos.
  const uniqueDays = new Set();

  const today = new Date();
  const dayOfMonth = today.getDate();

  data.forEach(item => {
    const examDate = new Date(item.datetime_exam);
    // Extraemos la parte de la fecha (YYYY-MM-DD)
    const dayString = examDate.toISOString().split('T')[0];
    uniqueDays.add(dayString);


  });


  const now = new Date();
  // Calculamos la diferencia en milisegundos y la convertimos a días
  const daysElapsed = dayOfMonth;
  const daysWithLectures = uniqueDays.size;
  return { daysElapsed, daysWithLectures }

}





  