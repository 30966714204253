import React from 'react';

import { motion } from 'framer-motion';


const HeaderCustomized = ({grado_estudiante,colegio_usuario,username}) => {
  return (
    <header className="h-16 w-full bg-gradient-to-r from-blue-500 to-purple-500 text-white flex items-center justify-between px-4 md:px-8 pt-4">
      {/* Sección de la imagen de perfil (puedes reemplazar la URL por la imagen real) */}
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5, delay: 0.4 }}
        className="cursor-pointer hover:scale-105 transition-transform"
      >
        <img
          src="https://via.placeholder.com/40"
          alt="Perfil"
          className="w-10 h-10 md:w-12 md:h-12 rounded-full border-2 border-white"
        />
        
      </motion.div>

      <div className="flex flex-col">
        <motion.h1
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-lg md:text-xl font-bold"
        >
          SUMAQTAI
        </motion.h1>

      </div>
      
      {/* Sección del título y slogan */}
      <div className="flex flex-col">
        <motion.h1
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-lg md:text-xl font-bold"
        >
          {username}
        </motion.h1>
        <motion.p
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="text-xs md:text-sm"
        >
         II.EE {colegio_usuario} -
         Grado {grado_estudiante}
        </motion.p>

      </div>

      
    </header>
  );
};

export default HeaderCustomized;
