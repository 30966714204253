import React, { useEffect, useRef, useState } from 'react';
import * as pdfjsLib from 'pdfjs-dist';
import * as pdfjsWorker from 'pdfjs-dist/build/pdf.worker.min.mjs';

// Configura el worker
//pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
pdfjsLib.GlobalWorkerOptions.workerSrc = 'pdfjs-dist/build/pdf.worker.min.mjs';


const PDFViewer = ({ pdfUrl }) => {
  const canvasRef = useRef(null);
  const [pdf, setPdf] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    // Carga el PDF
    const loadingTask = pdfjsLib.getDocument(pdfUrl);
    loadingTask.promise
      .then((pdf) => {
        setPdf(pdf);
        setTotalPages(pdf.numPages);
        renderPage(pdf, pageNumber);
      })
      .catch((error) => {
        console.error('Error al cargar el PDF:', error);
      });
  }, [pdfUrl]);

  const renderPage = (pdf, pageNum) => {
    pdf.getPage(pageNum)
      .then((page) => {
        const scale = 1.5; // Escala de la página
        const viewport = page.getViewport({ scale });

        // Configura el canvas
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        // Renderiza la página en el canvas
        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };
        page.render(renderContext);
      })
      .catch((error) => {
        console.error('Error al renderizar la página:', error);
      });
  };

  const goToPreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
      renderPage(pdf, pageNumber - 1);
    }
  };

  const goToNextPage = () => {
    if (pageNumber < totalPages) {
      setPageNumber(pageNumber + 1);
      renderPage(pdf, pageNumber + 1);
    }
  };

  return (
    <div>
      <canvas ref={canvasRef} style={{ border: '1px solid black' }} />
      <div className="pdf-controls">
        <button className="pdf-button" onClick={goToPreviousPage} disabled={pageNumber <= 1}>
            Anterior
        </button>
        <button className="pdf-button" onClick={goToNextPage} disabled={pageNumber >= totalPages}>
            Siguiente
        </button>
        <p>
            Página {pageNumber} de {totalPages}
        </p>
        </div>
    </div>
    
  );
};

export default PDFViewer;