// WeeklyReadingHabit.jsx
import React from 'react'
import { motion } from 'framer-motion'

/**
 * Componente que muestra círculos de colores basados en
 * el número de lecturas de cada día.
 *
 * @param {Array<number>} readingData - Array de 7 elementos con la cantidad de lecturas diarias.
 */
function MonthlyReadingHabit({ readingData = [] }) {
  // En caso de que no vengan los 7 días completos, aseguramos un array de 7 elementos.

  const days = readingData.slice(0, 30)

  // Función de ayuda para mapear un número de lecturas a una clase de color
  const getColorClass = (totalLectures, avgDailyScore) => {
    // Si no hay lecturas, devolvemos un gris muy suave
    if (totalLectures === 0) return 'bg-gray-100';
  
    // Primero, definimos el color base según el promedio diario
    let baseColor = '';
    if (avgDailyScore <= 0.45) {
      baseColor = 'red';
    } else if (avgDailyScore <= 0.65) {
      baseColor = 'orange';
    } else if (avgDailyScore <= 0.80) {
      baseColor = 'yellow';
    } else if (avgDailyScore <= 0.90) {
      baseColor = 'blue';
    } else if (avgDailyScore <= 1) {
      baseColor = 'green';
    } else {
      baseColor = 'purple';
    }
  
    /* 
      Ahora, en función de totalLectures, elegimos la intensidad del color.
      Usamos varios umbrales para devolver clases de Tailwind:
        - Menos de 10: tonalidad muy clara (100)
        - Entre 10 y 30: tonalidad ligera (300)
        - Entre 30 y 50: tonalidad media (500)
        - Entre 50 y 70: tonalidad oscura (700)
        - 70 o más: tonalidad muy oscura (900)
    */
    if (totalLectures < 10) return `bg-${baseColor}-100`;
    else if (totalLectures < 30) return `bg-${baseColor}-300`;
    else if (totalLectures < 50) return `bg-${baseColor}-500`;
    else if (totalLectures < 70) return `bg-${baseColor}-700`;
    else return `bg-${baseColor}-900`;
  };

  return (
    <div className="flex items-center justify-center space-x-2 p-1">
      {days.map((lecturas, index) => {
        
        const colorClass = getColorClass(lecturas.total_lectures,lecturas.avg_daily_score)
        return (
          <motion.div
            key={index}
            className={`w-8 h-8 rounded-full  ${colorClass}`}
            // Animaciones de Framer Motion
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ 
              delay: index * 0.1,  // retrasa cada círculo un poco
              type: 'spring', 
              stiffness: 100 
            }}
            title={`Día ${lecturas.date}: ${lecturas.total_lectures} lecturas, ${lecturas.avg_daily_score.toFixed(2)} comprensión`}
          />
        )
      })}


    </div>
  )
}

export default MonthlyReadingHabit
