import React from 'react';

const Speedometer = ({ value }) => {
  // Validar que el valor esté entre 0 y 1
  const clampedValue = Math.min(Math.max(value, 0), 1);

  // Función para obtener el estado y el color según el valor
  const getStatusAndColor = (value) => {
    if (value < 0.45) return { status: 'Pre-inicio', color: '#ef4444' };
    if (value < 0.65) return { status: 'Inicio', color: '#f97316' };
    if (value < 0.8) return { status: 'En Proceso', color: '#eab308' };
    if (value < 0.9) return { status: 'Logrado', color: '#3b82f6' };
    return { status: 'Satisfactorio', color: '#22c55e' };
  };

  const { status, color } = getStatusAndColor(clampedValue);

  // Parámetros del velocímetro
  const width = 325; // Ancho base del SVG
  const height = 160; // Alto del SVG
  const radius = 100; // Radio del semicírculo interno
  const outerRadius = 120; // Radio del semicírculo externo
  const centerX = width / 2 - 20; // Movemos el centro ligeramente a la izquierda
  const centerY = height; // Centro vertical (en la parte inferior)
  const startAngle = -180; // Ángulo inicial (izquierda)
  const endAngle = 0; // Ángulo final (derecha)
  const progressAngle = startAngle + clampedValue * (endAngle - startAngle); // Ángulo de progreso

  // Rangos y colores del arco externo
  const ranges = [
    { min: 0, max: 0.45, color: '#ef4444', label: 'Pre-inicio' }, // Pre-inicio
    { min: 0.45, max: 0.65, color: '#f97316', label: 'Inicio' }, // Inicio
    { min: 0.65, max: 0.8, color: '#eab308', label: 'En Proceso' }, // En Proceso
    { min: 0.8, max: 0.9, color: '#3b82f6', label: 'Logrado' }, // Logrado
    { min: 0.9, max: 1, color: '#22c55e', label: 'Satisfactorio' }, // Satisfactorio
  ];

  // Función para convertir grados a coordenadas en el SVG
  const getCoordinates = (angle, radius) => {
    const radians = (angle * Math.PI) / 180;
    return {
      x: centerX + radius * Math.cos(radians),
      y: centerY + radius * Math.sin(radians),
    };
  };

  return (
    <div style={{ width: '100%', maxWidth: '300px', margin: '0 auto' }}>
      <svg
        width="100%"
        height="auto"
        viewBox={`0 0 ${width} ${height}`} // Hacemos el SVG responsive
        preserveAspectRatio="xMidYMid meet"
      >
        {/* Arco externo con segmentos de colores */}
        {ranges.map((range, index) => {
          const segmentStartAngle = startAngle + range.min * (endAngle - startAngle)+1;
          const segmentEndAngle = startAngle + range.max * (endAngle - startAngle);
          const midAngle = (segmentStartAngle + segmentEndAngle) / 2; // Ángulo medio del segmento

          return (
            <React.Fragment key={index}>
              {/* Segmento del arco externo */}
              <path
                d={`
                  M ${getCoordinates(segmentStartAngle, outerRadius).x},${getCoordinates(segmentStartAngle, outerRadius).y}
                  A ${outerRadius},${outerRadius} 0 0 1 ${getCoordinates(segmentEndAngle, outerRadius).x},${getCoordinates(segmentEndAngle, outerRadius).y}
                `}
                fill="none"
                stroke={range.color}
                strokeWidth="10" // Ancho reducido a la mitad
              />
              {/* Etiqueta de texto */}
              <text
                x={getCoordinates(midAngle, outerRadius + 35).x} // Aumentamos el radio para más espacio
                y={getCoordinates(midAngle, outerRadius + 15).y}
                textAnchor="middle"
                fill={range.color}
                fontSize="10" // Tamaño de fuente pequeño
                dy="0.25em" // Ajustamos la posición vertical del texto
              >
                {range.label}
              </text>
            </React.Fragment>
          );
        })}

        {/* Fondo del velocímetro (arco interno) */}
        <path
          d={`
            M ${getCoordinates(startAngle, radius).x},${getCoordinates(startAngle, radius).y}
            A ${radius},${radius} 0 0 1 ${getCoordinates(endAngle, radius).x},${getCoordinates(endAngle, radius).y}
          `}
          fill="none"
          stroke="#e5e7eb"
          strokeWidth="10"
        />

        {/* Arco de progreso (arco interno) */}
        <path
          d={`
            M ${getCoordinates(startAngle, radius).x},${getCoordinates(startAngle, radius).y}
            A ${radius},${radius} 0 0 1 ${getCoordinates(progressAngle, radius).x},${getCoordinates(progressAngle, radius).y}
          `}
          fill="none"
          stroke={color} // Color dinámico según el valor
          strokeWidth="20"
          strokeLinecap="round"
        />

        {/* Aguja */}
        <line
          x1={centerX}
          y1={centerY}
          x2={getCoordinates(progressAngle, radius - 20).x}
          y2={getCoordinates(progressAngle, radius - 20).y}
          stroke="steelblue"
          strokeWidth="4"
          strokeLinecap="round"
        />

        {/* Etiqueta del estado */}
        <text
          x={centerX}
          y={centerY - 40}
          textAnchor="middle"
          fill={color}
          fontSize="20"
          fontWeight="bold"
        >
          {status} {/* Estado principal */}

        </text>

        {/* Valor actual */}
        <text
          x={centerX}
          y={centerY - 10}
          textAnchor="middle"
          fill="#6b7280"
          fontSize="24"
          fontWeight="bold"
        >
          {Math.round(clampedValue * 100)}%
        </text>
      </svg>
    </div>
  );
};

export default Speedometer;