import Cards from "../components/Cards";
import PublicS3Reader from '../components/s3/PublicS3Reader.js'
import HeaderCustomized from "../components/imgBackground/HeaderCustomized.js";

export default function First({ user, signOut }) {
  return (
    <div className='App'>
      <HeaderCustomized grado_estudiante={user.attributes['custom:Grado']} colegio_usuario={user.attributes['custom:Colegio']} username={user.attributes['given_name']} ></HeaderCustomized>


      <div>

            <Cards grado_estudiante={user.attributes['custom:Grado']} categoria_usuario={user.attributes['custom:categoria_usuario']} username={user.username} />
        </div>
    </div>
  );
}
